import { Suspense, lazy } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Outlet,
} from "react-router-dom";
import Preloader from "./components/preloader";
import Layout from "./layouts";
import Layout02 from "./layouts/layout-02";

// custom import
import { GoogleOAuthProvider } from "@react-oauth/google";

// Custom Pages
const DashboardCustom1 = lazy(() => import("./pages/dashboard-custom-01"));
const DashboardEquities = lazy(() => import("./pages/dashboard-equities"));

// Classic Pages

const SignIn = lazy(() => import("./pages/signin"));
const SignUp = lazy(() => import("./pages/signup"));
const VerifyAccount = lazy(() => import("./pages/verify-account"));
const ForgotPassword = lazy(() => import("./pages/forgot-password"));
const ErrorNotFound = lazy(() => import("./pages/error-404"));
const Error500 = lazy(() => import("./pages/error-500"));
const Error503 = lazy(() => import("./pages/error-503"));
const Error505 = lazy(() => import("./pages/error-505"));
const ProfileView = lazy(() => import("./pages/profile-view"));
const Connections = lazy(() => import("./pages/connections"));
const Groups = lazy(() => import("./pages/groups"));
const Events = lazy(() => import("./pages/events"));
const Timeline = lazy(() => import("./pages/timeline"));
const Pricing = lazy(() => import("./pages/pricing"));
const HelpCenter = lazy(() => import("./pages/help-center"));
const Invoice = lazy(() => import("./pages/invoice"));

// Classic Plus Pages

const ClassicPlusDashboardOne = lazy(
    () => import("./pages/classic-plus/dashboard-one")
);
const ClassicPlusDashboardTwo = lazy(
    () => import("./pages/classic-plus/dashboard-two")
);
const ClassicPlusDashboardThree = lazy(
    () => import("./pages/classic-plus/dashboard-three")
);
const ClassicPlusDashboardFour = lazy(
    () => import("./pages/classic-plus/dashboard-four")
);
const ClassicPlusDashboardFive = lazy(
    () => import("./pages/classic-plus/dashboard-five")
);
const ClassicPlusSignIn = lazy(() => import("./pages/classic-plus/signin"));
const ClassicPlusSignUp = lazy(() => import("./pages/classic-plus/signup"));
const ClassicPlusVerifyAccount = lazy(
    () => import("./pages/classic-plus/verify-account")
);
const ClassicPlusForgotPassword = lazy(
    () => import("./pages/classic-plus/forgot-password")
);
const ClassicPlusErrorNotFound = lazy(
    () => import("./pages/classic-plus/error-404")
);
const ClassicPlusError500 = lazy(
    () => import("./pages/classic-plus/error-500")
);
const ClassicPlusError503 = lazy(
    () => import("./pages/classic-plus/error-503")
);
const ClassicPlusError505 = lazy(
    () => import("./pages/classic-plus/error-505")
);

const ClassicPlusProfileView = lazy(
    () => import("./pages/classic-plus/profile-view")
);
const ClassicPlusConnections = lazy(
    () => import("./pages/classic-plus/connections")
);
const ClassicPlusGroups = lazy(() => import("./pages/classic-plus/groups"));
const ClassicPlusEvents = lazy(() => import("./pages/classic-plus/events"));
const ClassicPlusTimeline = lazy(() => import("./pages/classic-plus/timeline"));
const ClassicPlusPricing = lazy(() => import("./pages/classic-plus/pricing"));
const ClassicPlusHelpCenter = lazy(
    () => import("./pages/classic-plus/help-center")
);
const ClassicPlusInvoice = lazy(() => import("./pages/classic-plus/invoice"));

const App = () => {
    return (
        <>
            <GoogleOAuthProvider clientId="850963257083-vpfqub23855s3f5keimangtph0svp9ga.apps.googleusercontent.com">
                <Router>
                    <Suspense fallback={<Preloader />}>
                        <Routes>
                            {/* Classic Routes */}

                            <Route
                                element={
                                    <Layout>
                                        <Outlet />
                                    </Layout>
                                }
                            >
                                {/* Dashboard Routes */}
                                <Route
                                    path="/"
                                    // path="/todays-market"
                                    element={<DashboardCustom1 />}
                                />

                                <Route
                                    path="/top-stock"
                                    element={<DashboardEquities />}
                                />

                                {/* Authentication Routes */}
                                <Route path="/signin" element={<SignIn />} />
                                <Route path="/signup" element={<SignUp />} />
                                <Route
                                    path="/verify-account"
                                    element={<VerifyAccount />}
                                />
                                <Route
                                    path="/forgot-password"
                                    element={<ForgotPassword />}
                                />

                                {/* Error Routes */}
                                <Route
                                    path="/error-500"
                                    element={<Error500 />}
                                />
                                <Route
                                    path="/error-503"
                                    element={<Error503 />}
                                />
                                <Route
                                    path="/error-505"
                                    element={<Error505 />}
                                />
                                <Route
                                    path="/error-404"
                                    element={<ErrorNotFound />}
                                />

                                {/* User Routes */}
                                <Route
                                    path="/profile-view"
                                    element={<ProfileView />}
                                />
                                <Route
                                    path="/connections"
                                    element={<Connections />}
                                />
                                <Route path="/groups" element={<Groups />} />
                                <Route path="/events" element={<Events />} />

                                {/* Other Routes */}
                                <Route
                                    path="/timeline"
                                    element={<Timeline />}
                                />
                                <Route path="/pricing" element={<Pricing />} />
                                <Route
                                    path="/help-center"
                                    element={<HelpCenter />}
                                />
                                <Route path="/invoice" element={<Invoice />} />
                            </Route>

                            {/* Apps Routes */}

                            {/* App Route With Sidebar Layout 2 */}
                            <Route
                                element={
                                    <Layout
                                        hasSidebar
                                        hideFooter
                                        sidebarLayout={2}
                                    >
                                        <Outlet />
                                    </Layout>
                                }
                            />

                            {/* App Route With Sidebar*/}
                            <Route
                                element={
                                    <Layout hasSidebar hideFooter>
                                        <Outlet />
                                    </Layout>
                                }
                            />

                            {/* Classic Plus Routes */}

                            <Route
                                element={
                                    <Layout02>
                                        <Outlet />
                                    </Layout02>
                                }
                            >
                                {/* Dashboard Routes */}
                                <Route
                                    path="/classic-plus/dashboard-one"
                                    element={<ClassicPlusDashboardOne />}
                                />
                                <Route
                                    path="/classic-plus/dashboard-two"
                                    element={<ClassicPlusDashboardTwo />}
                                />
                                <Route
                                    path="/classic-plus/dashboard-three"
                                    element={<ClassicPlusDashboardThree />}
                                />
                                <Route
                                    path="/classic-plus/dashboard-four"
                                    element={<ClassicPlusDashboardFour />}
                                />
                                <Route
                                    path="/classic-plus/dashboard-five"
                                    element={<ClassicPlusDashboardFive />}
                                />

                                {/* Authentication Routes */}
                                <Route
                                    path="/classic-plus/signin"
                                    element={<ClassicPlusSignIn />}
                                />
                                <Route
                                    path="/classic-plus/signup"
                                    element={<ClassicPlusSignUp />}
                                />
                                <Route
                                    path="/classic-plus/verify-account"
                                    element={<ClassicPlusVerifyAccount />}
                                />
                                <Route
                                    path="/classic-plus/forgot-password"
                                    element={<ClassicPlusForgotPassword />}
                                />

                                {/* Error Routes */}
                                <Route
                                    path="/classic-plus/error-500"
                                    element={<ClassicPlusError500 />}
                                />
                                <Route
                                    path="/classic-plus/error-503"
                                    element={<ClassicPlusError503 />}
                                />
                                <Route
                                    path="/classic-plus/error-505"
                                    element={<ClassicPlusError505 />}
                                />
                                <Route
                                    path="/classic-plus/error-404"
                                    element={<ClassicPlusErrorNotFound />}
                                />

                                {/* User Routes */}
                                <Route
                                    path="/classic-plus/profile-view"
                                    element={<ClassicPlusProfileView />}
                                />
                                <Route
                                    path="/classic-plus/connections"
                                    element={<ClassicPlusConnections />}
                                />
                                <Route
                                    path="/classic-plus/groups"
                                    element={<ClassicPlusGroups />}
                                />
                                <Route
                                    path="/classic-plus/events"
                                    element={<ClassicPlusEvents />}
                                />

                                {/* Other Routes */}
                                <Route
                                    path="/classic-plus/timeline"
                                    element={<ClassicPlusTimeline />}
                                />
                                <Route
                                    path="/classic-plus/pricing"
                                    element={<ClassicPlusPricing />}
                                />
                                <Route
                                    path="/classic-plus/help-center"
                                    element={<ClassicPlusHelpCenter />}
                                />
                                <Route
                                    path="/classic-plus/invoice"
                                    element={<ClassicPlusInvoice />}
                                />
                            </Route>

                            {/* Apps Routes */}

                            {/* App Route With Minimize Sidebar*/}
                            <Route
                                element={
                                    <Layout02 aside="minimize">
                                        <Outlet />
                                    </Layout02>
                                }
                            />

                            {/* App Route With Sidebar Layout 2*/}
                            <Route
                                element={
                                    <Layout02 aside="minimize">
                                        <Outlet />
                                    </Layout02>
                                }
                            />

                            {/* 404 Page Route */}
                            <Route
                                element={
                                    <Layout>
                                        <Outlet />
                                    </Layout>
                                }
                            >
                                <Route path="*" element={<ErrorNotFound />} />
                            </Route>
                        </Routes>
                    </Suspense>
                </Router>
            </GoogleOAuthProvider>
        </>
    );
};

export default App;
