import { StyledLogo, StyledLogoImage } from "./style";
import logo_dark from "./dark-theme-logo.png";
import logo_light from "./light-theme-logo.png";

const AsideLogo = () => {
    const themeno = 1;
    const logo = themeno ? logo_light : logo_dark;

    return (
        // <StyledLogo className="aside-logo" path="/">
        //     k<span>Dence</span>Stock
        // </StyledLogo>
        <StyledLogoImage src={logo} alt="logoname_white"></StyledLogoImage>
    );
};

export default AsideLogo;
