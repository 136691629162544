import { StyledLogo, StyledLogoImage } from "./style";
// import { Image } from "@doar/components";
// import { useAppSelector } from "src/redux/hooks";
import { useAppSelector } from "../../../../../packages/main/src/redux/hooks";

import logo_dark from "./dark-theme-logo.png";
import logo_light from "./light-theme-logo.png";
import { Button } from "@doar/components";

const Logo = () => {
    const themeState = useAppSelector((state) => state.theme);
    const themeno = themeState.theme == "dark" ? 0 : 1;
    const logo = themeno ? logo_light : logo_dark;

    return (
        // <StyledLogo path="/">
        //     K<span>dencestock</span>
        // </StyledLogo>
        // <Image src={logo} alt="logo with name" fluid></Image>
        <Button fullwidth path="/" variant="texted">
            <StyledLogoImage src={logo} alt="logoname_white"></StyledLogoImage>
        </Button>
    );
};

export default Logo;

//     {/* <img
//         src={logo_dark}
//         alt="logoname_white"
//         // style={{ maxHeight: "100%", maxWidth: "100%" }}
//     ></img> */}
