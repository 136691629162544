/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TTheme } from "@doar/shared/types";

export interface UserState {
    firstName: string;
    lastName: string;
    nickName: string;
    loginBy: "origin" | "google" | "facebook" | "none";
    email: string;
    profileImageLink: string;
}

const initialState: UserState = {
    firstName: "",
    lastName: "",
    nickName: "",
    loginBy: "none",
    email: "",
    profileImageLink: "",
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        assignUser(state, action: PayloadAction<{ userState: UserState }>) {
            const {
                payload: { userState },
            } = action;
            state = state.loginBy == "none" ? state : userState;
        },
        logOutUser(state) {
            state = state.loginBy != "none" ? initialState : state;
        },
    },
});

export const { assignUser } = userSlice.actions;
export default userSlice.reducer;
