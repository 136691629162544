import styled, { device, themeGet, css } from "@doar/shared/styled";
import { Anchor } from "@doar/components";

export const StyledLogo = styled(({ ...rest }) => <Anchor {...rest} />)`
    font-weight: 700;
    font-size: 22px;
    letter-spacing: -1px;
    color: inherit;
    display: flex;
    align-items: center;
    position: relative;
    color: ${themeGet("colors.gulf")};
    margin-top: -4px;
    span {
        display: inline-block;
        font-weight: 400;
        color: ${themeGet("colors.primary")};
    }
    ${device.small} {
        font-size: 22px;
    }
    ${device.large} {
        font-size: 24px;
    }
    ${({ theme }) =>
        theme.name === "dark" &&
        css`
            color: ${themeGet("colors.white")};
        `}
`;

// export const StyledLogoImage = styled.img`
//     max-height: 100%;
//     max-width: 100%;
// `;

// export const StyledLogoImage = styled.img`
//     max-height: 50%;
//     max-width: 50%;
// `;

export const StyledLogoImage = styled.img`
    color: inherit;
    display: flex;
    align-items: center;
    position: relative;
    max-width: 30vw;
    max-height: 50px;
    margin-top: -4px;
    // border: 2px solid ${themeGet("colors.primary")};
    ${device.small} {
        max-height: 50px;
        max-width: 30vw;
    }
    ${device.large} {
        max-height: 55px;
        max-width: 15vw;
    }
    @media (max-width: 575.98px) {
        max-width: 40vw;
    }
    @media (max-width: 330px) {
        display: none;
    }
`;
